<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__content">
        <div class="d-sm-flex align-items-center justify-content-between mb-4 mt-4">
          <h3>Payment Methods</h3>
          <div class="justify-content-between">
            <router-link :to="{name: 'admin_create_payment_method'}" class="ps-btn btn-sm"><i class="icon-plus"></i> Create</router-link>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table ps-table ps-table--vendor">
            <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Slug</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="paymentMethod in paymentMethods" :key="paymentMethod.id">
                  <td>{{ paymentMethod.id }}</td>
                  <td><span class="text-primary">{{ paymentMethod.name }}</span></td>
                  <td>{{ paymentMethod.slug }}</td>
                  <td>
                    <span v-if="paymentMethod.is_active" class="text-success">Active </span>
                    <span v-else class="text-danger">Disabled </span>
                  </td>
                  <td>
                    <router-link :to="{name: 'admin_edit_payment_method', params: {id: paymentMethod.id}}">
                      <i class="icon-pencil"></i>
                    </router-link>
                  </td>
                </tr>
            </tbody>
            <tfoot>
                <tr v-if="!isLoading && paymentMethods.length === 0">
                  <td colspan="7" class="text-center m-4 text-danger p-4">No payment methods available</td>
                </tr>
                <tr v-else-if="isLoading">
                  <td colspan="7" class="text-center m-4 text-danger">Loading payment methods...</td>
                </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      paymentMethods:[],
      meta: null,
      isLoading: false
    };
  },
  mounted() {
    this.getPaymentMethods();
  },
  methods: {
    getPaymentMethods() {
      this.isLoading = true
      axios.get('payment-methods').then((response) => {
        this.paymentMethods = response.data
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      });
    }
  }
};
</script>